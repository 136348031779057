import * as React from 'react'
import Layout from '../components/layout'
import Gallery from '../components/gallery'
import gallerystyle from '../components/gallerystyle.css'

const galleryImages = [
    {
        img: '/images/photos/20220121.jpg',
        altText: 'Adams Wilson 2022',
        title: 'Adams Wilson 2022'
    },
    {
        img: '/images/photos/adams_wilson_band_20181211A.jpg',
        altText: 'Adams Wilson 2019: Ryan Buzby, Adams Eberwein, Katie Skwirut, Dan Illi',
        title: 'Adams Wilson c. 2019: Ryan Buzby, Adams Eberwein, Katie Skwirut, Dan Illi'
    },
    {
        img: '/images/photos/max_photoshoot_20170201.jpg',
        altText: 'Adams Eberwein 2017',
        title: 'Adams Eberwein, c. 2017'
    },
    {
        img: '/images/photos/max_photoshoot_20170201A.jpg',
        altText: 'Adams Eberwein 2017',
        title: 'Adams Eberwein, c. 2017'
    },
    {
        img: '/images/photos/20160819_ardmore_music_hall.jpg',
        altText: 'Adams Wilson at Ardmore Music Hall, Ardmore, PA, 08/19/2016',
        title: 'Adams Wilson at Ardmore Music Hall, Ardmore, PA, 08/19/2016'
    },
    {
        img: '/images/photos/20160730_wonderbar.jpg',
        altText: 'Adams Wilson at Wonderbar, Asbury Park, NJ, 07/30/2016',
        title: 'Adams Wilson at Wonderbar, Asbury Park, NJ, 07/30/2016'
    },
    {
        img: '/images/photos/20160516_world_cafe_live.jpg',
        altText: 'Adams Wilson at World Cafe Live, Philadelphia, PA 05/16/2016',
        title: 'Adams Wilson at World Cafe Live, Philadelphia, PA 05/16/2016'
    },
    {
        img: '/images/photos/20160320_volume_cafe.jpg',
        altText: 'Adams Wilson at Volume Cafe, Turnersville, NJ, 03/20/2016',
        title: 'Adams Wilson at Volume Cafe, Turnersville, NJ, 03/20/2016'
    },
    {
        img: '/images/photos/20150905_wonderbar.jpg',
        altText: 'Adams Wilson at Wonderbar, Asbury Park, NJ, 09/05/2015',
        title: 'Adams Wilson at Wonderbar, Asbury Park, NJ, 09/05/2015'
    },
    {
        img: '/images/photos/annette_20150701.jpg',
        altText: 'Annette Williams 2015',
        title: 'Annette Williams, c. 2015'
    },
    {
        img: '/images/photos/matt_20150701.jpg',
        altText: 'Matt Riley 2015',
        title: 'Matt Riley, c. 2015'
    },
    {
        img: '/images/photos/bio-band-2280.jpg',
        altText: 'Adams Wilson: Adams Eberwein, Brian Scorben, Annette Williams, Ryan Buzby, Matt Riley',
        title: 'Adams Wilson: Adams Eberwein, Brian Scorben, Annette Williams, Ryan Buzby, Matt Riley'
    },
    {
        img: '/images/photos/bio-2015-brian2.jpg',
        altText: 'Brian Scorben',
        title: 'Brian Scorben'
    },
    {
        img: '/images/photos/20150721.jpg',
        altText: 'Adams Wilson c. 2015: Matt Riley, Brian Scorben, Adams Eberwein, Annette Williams, Bob Foulks',
        title: 'Adams Wilson c. 2015: Matt Riley, Brian Scorben, Adams Eberwein, Annette Williams, Bob Foulks'
    },
    {
        img: '/images/photos/bio-2015-annette2.jpg',
        altText: 'Annette Williams',
        title: 'Annette Williams'
    },
    {
        img: '/images/photos/bio-2015-annette1.jpg',
        altText: 'Annette Williams',
        title: 'Annette Williams, Prairie Fires Release Show, 5/9/2015'
    },
    {
        img: '/images/photos/bio-2015-matt2.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley, Prairie Fires Release Show, 5/9/2015'
    },
    {
        img: '/images/photos/bio-2015-matt1.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley, Prairie Fires Release Show, 5/9/2015'
    },
    {
        img: '/images/photos/bio-2015-adams1.jpg',
        altText: 'Adams Eberwein',
        title: 'Adams Eberwein, Prairie Fires Release Show, 5/9/2015'
    },
    {
        img: '/images/photos/tony.jpg',
        altText: 'Toney Rhodes',
        title: 'Toney Rhodes recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/mike_upright_2.jpg',
        altText: 'Mike Mean',
        title: 'Mike Mean recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'        
    },
    {
        img: '/images/photos/matt_dobro_2.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/matt_mando.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley recording for Prairie Fires at GCR Audio in Buffalo, October, 2014'
    },
    {
        img: '/images/photos/mike_bass_2.jpg',
        altText: 'Mike Mean',
        title: 'Mike Mean recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'        
    },
    {
        img: '/images/photos/mike_console.jpg',
        altText: 'Mike Mean',
        title: 'Mike Mean recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'        
    },
    {
        img: '/images/photos/matt_acx_2.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/matt_acx_3.jpg',
        altText: 'Matt Riley',
        title: 'Matt Riley recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/katie_cello.jpg',
        altText: 'Katie cello',
        title: 'Katie recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/kimera.jpg',
        altText: 'Kimera Lattimore vocalist',
        title: 'Kimera Lattimore recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/justin_pedal.jpg',
        altText: 'Justin Rose, GCR Audio, Buffalo',
        title: 'Justin Rose recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/justin.jpg',
        altText: 'Justin Rose, GCR Audio, Buffalo',
        title: 'Justin Rose recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/joe.jpg',
        altText: 'Joe GCR Audio, Buffalo',
        title: 'Joe recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/howard_2.jpg',
        altText: 'Howard Wilson GCR Audio, Buffalo',
        title: 'Howard Wilson recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/howard.jpg',
        altText: 'Howard Wilson GCR Audio, Buffalo',
        title: 'Howard Wilson recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/adams_vox.jpg',
        altText: 'Adams Eberwein GCR Audio, Buffalo',
        title: 'Adams Eberwein, recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/adams_acx.jpg',
        altText: 'Adams Eberwein, GCR Audio, Buffalo',
        title: 'Adams Eberwein recording for Prairie Fires at GCR Audio in Buffalo, NY, October, 2014'
    },
    {
        img: '/images/photos/20140510_auburn_road.jpg',
        altText: 'Adams Wilson at Auburn Road Winery, 05/10/2014',
        title: 'Adams Wilson at Auburn Road Winery, Pilesgrove, NJ, 05/10/2014'
    },
    {
        img: '/images/photos/wine_ian.jpg',
        altText: 'Ian Gattuso performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Ian Gattuso performing at Auburn Road Winery, Pilesgrove, NJ'
    },
    {
        img: '/images/photos/bio-2015-brian1.jpg',
        altText: 'Brian Scorben',
        title: 'Brian Scorben'
    },
    {
        img: '/images/photos/panorama_three.jpg',
        altText: 'Adams Wilson at Woodstown Music, Woodstown, NJ',
        title: 'Adams Wilson at Woodstown Music, Woodstown, NJ'
    },
    {
        img: '/images/photos/adwil_banner.jpg',
        altText: 'Adams Wilson banner',
        title: 'Adams Wilson banner'
    },
    {
        img: '/images/photos/adwil_drumhead.jpg',
        altText: 'Adams Wilson Spaceman logo',
        title: 'Adams Wilson Spaceman logo'
    },
    {
        img: '/images/photos/20130815_parx.jpg',
        altText: 'Adams Wilson at Parx Casino, Bensalem, NJ, 08/15/2013',
        title: 'Adams Wilson at Parx Casino, Bensalem, NJ, 08/15/2013'
    },
    {
        img: '/images/photos/20130414_hebe_music.jpg',
        altText: 'Adams Wilson at Hebe Music, Mt Holly, NJ, 04/14/2013',
        title: 'Adams Wilson at Hebe Music, Mt Holly, NJ, 04/14/2013'
    },
    {
        img: '/images/photos/auburn_20130202.jpg',
        altText: 'Adams Wilson performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Adams Wilson performing at Auburn Road Winery, Pilesgrove, NJ'
    },  
    {
        img: '/images/photos/20130106.jpg',
        altText: 'Adams Wilson in Carneys Point, NJ, 2013',
        title: 'Adams Wilson in Carneys Point, NJ, 2013'
    },  
    {
        img: '/images/photos/20121013_adams.jpg',
        altText: 'Adams Eberwein performing at Woodstown Fall Festival, Woodstown, NJ',
        title: 'Adams Eberwein performing at Woodstown Fall Festival, Woodstown, NJ, 10/28/2012'
    },
    {
        img: '/images/photos/20121013_brian.jpg',
        altText: 'Brian Scorben performing at Woodstown Fall Festival, Woodstown, NJ',
        title: 'Brian Scorben performing at Woodstown Fall Festival, Woodstown, NJ, 10/28/2012'
    },
    {
        img: '/images/photos/20121013_darren.jpg',
        altText: 'Darren Darling performing at Woodstown Fall Festival, Woodstown, NJ',
        title: 'Darren Darling performing at Woodstown Fall Festival, Woodstown, NJ, 10/28/2012'
    },
    {
        img: '/images/photos/20121013_matt.jpg',
        altText: 'Matt Riley performing at Woodstown Fall Festival, Woodstown, NJ',
        title: 'Matt Riley performing at Woodstown Fall Festival, Woodstown, NJ, 10/28/2012'
    },
    {
        img: '/images/photos/adams_winery_2.jpg',
        altText: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ, 03/10/2012'
    },
    {
        img: '/images/photos/band_2012.jpg',
        altText: 'Adams Wilson, c. 2012',
        title: 'Adams Wilson, c. 2012: Matt Riley, Adams Eberwein, Darren Darling, Brian Scorben'
    },
    {
        img: '/images/photos/20110518_wstw.jpg',
        altText: 'Adams Wilson on WSTWs Hometown Heroes, 05/18/2011',
        title: 'Adams Wilson on WSTWs Hometown Heroes, 05/18/2011'
    },
    {
        img: '/images/photos/20100529_list.jpg',
        altText: 'Adams Wilson setlist',
        title: 'Adams Wilson setlist'
    },
    {
        img: '/images/photos/hangar_darren.jpg',
        altText: 'Darren Darling at Hangar 84, Vineland, NJ, 06/19/2010',
        title: 'Darren Darling at Hangar 84, Vineland, NJ, 06/19/2010'
    },
    {
        img: '/images/photos/hangar_adams.jpg',
        altText: 'Adams Eberwein at Hangar 84, Vineland, NJ, 06/19/2010',
        title: 'Adams Eberwein at Hangar 84, Vineland, NJ, 06/19/2010'
    },
    {
        img: '/images/photos/20100529_matt2.jpg',
        altText: 'Matt Riley performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Matt Riley performing at Auburn Road Winery, Pilesgrove, NJ'
    },
    {
        img: '/images/photos/20100529_band.jpg',
        altText: 'Adams Wilson performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Adams Wilson performing at Auburn Road Winery, Pilesgrove, NJ, 05/29/2010'
    },
    {
        img: '/images/photos/20100529_adams2.jpg',
        altText: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ'
    },
    {
        img: '/images/photos/20100529_auburn_road.jpg',
        altText: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ',
        title: 'Adams Eberwein performing at Auburn Road Winery, Pilesgrove, NJ'
    },
    {
        img: '/images/photos/adams_wilson_2010.jpg',
        altText: 'Adams Wilson 2010: Adams Eberwein, Jason Rugel, Matt Riley, Darren Darling',
        title: 'Adams Wilson, c. 2010: Adams Eberwein, Jason Rugel, Matt Riley, Darren Darling'
    },
    {
        img: '/images/photos/fair_band.jpg',
        altText: 'Adams Wilson at Salem County Fair, Woodstown, NJ',
        title: 'Adams Wilson (Matt Riley, Josh Dubois, Jason Rugel, Ian Gattuso, Adams Eberwein) at Salem County Fair, Woodstown, NJ'
    },
    {
        img: '/images/photos/pumpkin_adams.jpg',
        altText: 'Adams Eberwein at South Jersey Pumpkin Show',
        title: 'Adams Eberwein at South Jersey Pumpkin Show'
    },
    {
        img: '/images/photos/pumpkin_matt.jpg',
        altText: 'Matt Riley at South Jersey Pumpkin Show',
        title: 'Matt Riley at South Jersey Pumpkin Show'
    },
    {
        img: '/images/photos/band_photo_2.jpg',
        altText: 'Adams Wilson',
        title: 'Adams Wilson: Ian Gattuso, Matt Riley, Josh Dubois, Jason Rugel, Adams Eberwein'
    },
    {
        img: '/images/photos/stuntwork.jpg',
        altText: 'Harry Matlack filming for the Spaceman music video',
        title: 'Harry Matlack filming for the Spaceman music video'
    },
    {
        img: '/images/photos/20081009_auburn_road_my_best_guess.jpg',
        altText: 'Adams Wilson My Best Guess release show, Auburn Road Winery, Pilesrove, NJ, 2008',
        title: 'Adams Wilson My Best Guess release show, Auburn Road Winery, Pilesrove, NJ, 2008'
    },
    {
        img: '/images/photos/harry1.jpg',
        altText: 'Harry Matlack recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Harry Matlack recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/nate5.jpg',
        altText: 'Nate Gonzalez recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Nate Gonzalez recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/ian1.jpg',
        altText: 'Ian Gattuso recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Ian Gattuso recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/deena1.jpg',
        altText: 'Deena Parr recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Deena Parr recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/gary2.jpg',
        altText: 'Gary Scheffler recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Gary Scheffler recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/brian1.jpg',
        altText: 'Brian McTear recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Brian McTear recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/adams2.jpg',
        altText: 'Adams Eberwein recording for My Best Guess at Miner Street Recordings, Philadelphia, PA',
        title: 'Adams Eberwein recording for My Best Guess at Miner Street Recordings, Philadelphia, PA'
    },
    {
        img: '/images/photos/woodstown_diner.jpg',
        altText: 'Adams Wilson at Woodstown Diner, Woodstown, NJ',
        title: 'Adams Wilson at Woodstown Diner, Woodstown, NJ'
    },
    {
        img: '/images/photos/farm.jpg', 
        altText: 'Adams Eberwein filming for If Ever at Buzby Farm, Mannington, NJ',
        title: 'Adams Eberwein filming for If Ever at Buzby Farm, Mannington, NJ'
    },
    {
        img: '/images/photos/bwchurch.jpg',
        altText: 'Adams Eberwein',
        title: 'Adams Eberwein'
    },
]

const PhotoPage = () => {
    return (
        <Layout pageTitle="Adams Wilson Photos">
            <div>
                <Gallery 
                galleryImages={galleryImages} 
                />
            </div>
        </Layout>
    )
}

export default PhotoPage