import { DragHandle } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'

const Gallery = ({galleryImages}) => {

const [slideNumber, setSlideNumber] = useState(0)
const [openModal, setOpenModal] = useState(false)

const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
}

//close Modal
const handleCloseModal = () => {
    setOpenModal(false)
}

//previous image
const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber(galleryImages.length - 1) 
    : setSlideNumber(slideNumber - 1)
}

//next image
const nextSlide = () => {
   slideNumber + 1 === galleryImages.length 
   ? setSlideNumber(0) 
   : setSlideNumber(slideNumber + 1) 
}

  return (
      <div>

{openModal && 
<div className='sliderWrap'>
    <span className='btnClose' onClick={handleCloseModal}><svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
</span>
    <span className='btnPrev' onClick={prevSlide}><svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
</span>
    <span className='btnNext' onClick={nextSlide}><svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>
</span>
    <div className='fullScreenImage'>
        <img src={galleryImages[slideNumber].img} alt={galleryImages[slideNumber].altText} />
        <div className='photoTitleBox'><p className='photoTitle'>{galleryImages[slideNumber].title}</p></div>
    </div>
</div>
}

        <div className='galleryWrap'>
            {
                galleryImages && galleryImages.map((slide, index) => {
                    return(
                        <div className='single' 
                        key={index}
                        onClick={ () => handleOpenModal(index) }
                        >
                        <img src={slide.img} alt={slide.altText} className='thumbnail' />
                        </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Gallery